<template>
    <div>
        <table class="styled-table">
        <thead>
            <tr>
                <th></th>
                <th>Quantity</th>
                <th>Unit Price</th>
            </tr>
        </thead>
        <tbody v-if="errorGettingWeight">
            <tr>
                <td colspan="3">Error while converting weight</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td>Meter</td>
                <td>{{ weightConvertorResult.meter }}</td>
                <td>{{ weightConvertorResult.unitPricePerMeter }}</td>
            </tr>
            <tr>
                <td>Tonne</td>
                <td>{{ weightConvertorResult.tonne }}</td>
                <td>{{ weightConvertorResult.unitPricePerTonne }}</td>
            </tr>
            <tr>
                <td>Foot</td>
                <td>{{ weightConvertorResult.foot }}</td>
                <td>{{ weightConvertorResult.unitPricePerFoot }}</td>
            </tr>
            <tr>
                <td>Joint</td>
                <td>{{ weightConvertorResult.joint }}</td>
                <td>{{ weightConvertorResult.unitPricePerJoint }}</td>
            </tr>
        </tbody>
        </table>
    </div>
</template>

<script setup>
import { ref, watchEffect, defineProps } from 'vue';
import { DataService } from '@/config/dataService/dataService';

const props = defineProps(['uomCode', 'quantity', 'weight', 'rangeCode', 'totalPrice']);
const weightConvertorResult = ref({});
const errorGettingWeight = ref(false);

const getWeightConversion = async () => {
    try {
        const query = await DataService.get(
            `api/Weight/ConvertWeightAndPrice?UomCode=${props.uomCode}&Quantity=${props.quantity}&Weight=${props.weight}&RangeCode=${props.rangeCode}&TotalPrice=${props.totalPrice}`
        );

        if (query.succeeded) {
            weightConvertorResult.value = query.data;
            errorGettingWeight.value = false;
        } else {
            errorGettingWeight.value = true;
        }
    } catch (error) {
        errorGettingWeight.value = true;
    }
};

watchEffect(() => {
    getWeightConversion();
});
</script>

<style scoped>
.styled-table {
    border-spacing: 0;
    width: 100%;
    margin: 20px 0;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #f00;
    border-radius: 10px;
}

.styled-table th,
.styled-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.styled-table th {
    background-color: #f2f2f2;
}

.styled-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.styled-table tr:hover {
    background-color: #f5f5f5;
}
</style>